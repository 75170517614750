export default {
  cake: {
    137: '0x214188993b36bD8cD15f805Adee9eb49fD80BA74',
  },
  masterChef: {
    137: '0x06Aeddc58d6148ECF2F199Bd0f57876e5f94Bcb8',
  },
  vaultChef: {
    137: '0x47659fae079Bf503342f855c1bfa471BC65A7D22',
  },
  lottery: {
    137: '',
  },
  instinctToken: {
    137: '0xEc22E26DA6b5E841ad763A2C739F0F482915cD0e',
  },
  instinctSwap: {
    137: '0xee35dF0D6c62ef66aBbEC050bd73894E8B3FCDD4',
  },
  theEndlessFactoryNFT: {
    137: '0x743F554f6AcCd4E452AF6C96c48B78E849d87316',
    // 137: '0x2487DF8D510cC0A9ec99fcE1F1d43adb3F6C731d', // Test
  },
  nftAirdrop: {
    137: '0xCE9eDBbbc8D11D593DA5C732C6602870084Fa98b',
  },
  nftSale: {
    137: '0xD6Ef4474e916f16bc86d32A9F88e09733920Fdd7',
  },
  nftMergeApi: {
    137: '0xbC50873a9C692A0aB7df352272Ec2aC297EA294a',
  },
  nftMerge: {
    137: '0xBd235282E6d84e27ACD7b8D86558F8923fAe0165',
  },
  destinyReferral: {
    137: '',
  },
  lotteryNFT: {
    137: '',
  },
  mulltiCall: {
    137: '0xEF3d4160E3d193E3d1494a4A9cF9eEA05E63a324',
  },
  wmatic: {
    137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  },
  matic: {
    137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  },
  wbnb: {
    137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  },
  bnb: {
    137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  },
  wbtc: {
    137: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
  },
  weth: {
    137: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  eth: {
    137: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  usdc: {
    137: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  },
  busd: {
    137: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  },
  usdt: {
    137: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
  },
  dai: {
    137: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
  },
  link: {
    137: '0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39',
  },
  mai: {
    137: '0xa3fa99a148fa48d14ed51d610c367c61876997f1',
  },
  delirium: {
    137: '0x238779aFfE6FFD475cB7e84582FcA7789F310Dc8',
  },
  despair: {
    137: '0x898F53e0365b3e8114227Df4E811766afCA960b8',
  },
  destruction: {
    137: '0xCa4992F01B63C7cEB98505946b79D7D8855449F9',
  },
  desire: {
    137: '0xfbbEa521578059D8c2D53899E44c5A68b8ee88D8',
  },
}
