import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 1,
    risk: 5,
    from: 'QuickSwap',
    lpSymbol: 'DESTINY-MATIC LP',
    decimal: 18,
    lpAddresses: {
      137: '0x8dc7a8e7040832920831436Fb370ea12f661f797',
    },
    tokenSymbol: 'DESTINY',
    tokenAddresses: {
      137: '0x214188993b36bD8cD15f805Adee9eb49fD80BA74',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wmatic,
  },
  {
    pid: 0,
    risk: 5,
    from: 'QuickSwap',
    lpSymbol: 'DESTINY-USDC LP',
    decimal: 18,
    lpAddresses: {
      137: '0x1052c59e067Ca92A261F9813A92900e8eFAE514d',
    },
    tokenSymbol: 'DESTINY',
    tokenAddresses: {
      137: '0x214188993b36bD8cD15f805Adee9eb49fD80BA74',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 3,
    risk: 5,
    from: 'QuickSwap',
    lpSymbol: 'MATIC-USDC LP',
    decimal: 18,
    lpAddresses: {
      137: '0x6e7a5FAFcec6BB1e78bAE2A1F0B612012BF14827',
    },
    tokenSymbol: 'MATIC',
    tokenAddresses: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },

  {
    pid: 2,
    risk: 5,
    from: 'QuickSwap',
    isTokenOnly: true,
    lpSymbol: 'DESTINY',
    decimal: 18,
    lpAddresses: {
      137: '0x1052c59e067Ca92A261F9813A92900e8eFAE514d',
    },
    tokenSymbol: 'DESTINY',
    tokenAddresses: {
      137: '0x214188993b36bD8cD15f805Adee9eb49fD80BA74',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 12,
    risk: 3,
    from: 'QuickSwap',
    isTokenOnly: true,
    lpSymbol: 'DESIRE',
    decimal: 18,
    lpAddresses: {
      137: '0x5C2d9217aeF2B7478F3Cf301619CAd236c345567',
    },
    tokenSymbol: 'DESIRE',
    tokenAddresses: {
      137: '0xfbbEa521578059D8c2D53899E44c5A68b8ee88D8',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 11,
    risk: 3,
    from: 'QuickSwap',
    isTokenOnly: true,
    lpSymbol: 'DESTRUCTION',
    decimal: 18,
    lpAddresses: {
      137: '0x287bD6518aE54a4299BD766faa6DF3ED795fB6C1',
    },
    tokenSymbol: 'DESTRUCTION',
    tokenAddresses: {
      137: '0xCa4992F01B63C7cEB98505946b79D7D8855449F9',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 10,
    risk: 3,
    from: 'QuickSwap',
    isTokenOnly: true,
    lpSymbol: 'DESPAIR',
    decimal: 18,
    lpAddresses: {
      137: '0x92BB40fa63EB4f402Cae5bDDAb3369a4Fb3e33bF',
    },
    tokenSymbol: 'DESPAIR',
    tokenAddresses: {
      137: '0x898F53e0365b3e8114227Df4E811766afCA960b8',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 9,
    risk: 3,
    from: 'QuickSwap',
    isTokenOnly: true,
    lpSymbol: 'DELIRIUM',
    decimal: 18,
    lpAddresses: {
      137: '0x2F051dB1634eEa2081590860ab8E756D452f2239',
    },
    tokenSymbol: 'DELIRIUM',
    tokenAddresses: {
      137: '0x238779aFfE6FFD475cB7e84582FcA7789F310Dc8',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 4,
    risk: 3,
    from: 'QuickSwap',
    isTokenOnly: true,
    lpSymbol: 'WMATIC',
    decimal: 18,
    lpAddresses: {
      137: '0x6e7a5FAFcec6BB1e78bAE2A1F0B612012BF14827',
    },
    tokenSymbol: 'MATIC',
    tokenAddresses: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 7,
    risk: 3,
    from: 'QuickSwap',
    isTokenOnly: true,
    lpSymbol: 'WETH',
    decimal: 18,
    lpAddresses: {
      137: '0x853Ee4b2A13f8a742d64C8F088bE7bA2131f670d',
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      137: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 8,
    risk: 3,
    from: 'QuickSwap',
    isTokenOnly: true,
    lpSymbol: 'USDC',
    decimal: 6,
    lpAddresses: {
      137: '0x2cf7252e74036d1da831d11089d326296e64a728',
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      137: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
  {
    pid: 6,
    risk: 3,
    from: 'QuickSwap',
    isTokenOnly: true,
    lpSymbol: 'USDT',
    decimal: 6,
    lpAddresses: {
      137: '0x2cf7252e74036d1da831d11089d326296e64a728',
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      137: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 5,
    risk: 3,
    from: 'QuickSwap',
    isTokenOnly: true,
    lpSymbol: 'MAI',
    decimal: 16,
    lpAddresses: {
      137: '0x7805B64e2d99412d3b8F10Dfe8fc55217C5cc954',
    },
    tokenSymbol: 'MAI',
    tokenAddresses: {
      137: '0xa3fa99a148fa48d14ed51d610c367c61876997f1',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.bnb,
  },
]

export default farms
