import contracts from './contracts'
import { VaultConfig, QuoteToken } from './types'

const vaults: VaultConfig[] = [
  {
    pid: 1,
    farmPid: 1,
    lpSymbol: 'DESTINY-MATIC',
    decimal: 18,
    lpAddresses: {
      137: '0x8dc7a8e7040832920831436Fb370ea12f661f797',
    },
    tokenSymbol: 'DESTINY',
    tokenAddresses: {
      137: '0x214188993b36bD8cD15f805Adee9eb49fD80BA74',
    },
    quoteTokenSymbol: QuoteToken.MATIC,
    quoteTokenAdresses: contracts.wmatic,
    earnSymbol: 'DESTINY-MATIC',
    earnDecimal: 18,
    from: 'SANDMAN',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      137: '0x2918f9B7E2919A9E3B87591d089ba6484DC61658',
    },
    compoundFrequency: 3,
    performanceFee: 6.66,
  },
  {
    pid: 0,
    farmPid: 0,
    lpSymbol: 'DESTINY-USDC',
    decimal: 18,
    lpAddresses: {
      137: '0x1052c59e067Ca92A261F9813A92900e8eFAE514d',
    },
    tokenSymbol: 'DESTINY',
    tokenAddresses: {
      137: '0x214188993b36bD8cD15f805Adee9eb49fD80BA74',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    earnSymbol: 'DESTINY-USDC',
    earnDecimal: 18,
    from: 'SANDMAN',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      137: '0x3EF6c76313F2854C0C6e521f95252B30581429eb',
    },
    compoundFrequency: 3,
    performanceFee: 6.66,
  },
  {
    pid: 6,
    farmPid: 2,
    isTokenOnly: true,
    lpSymbol: 'DESTINY',
    decimal: 18,
    lpAddresses: {
      137: '0x1052c59e067Ca92A261F9813A92900e8eFAE514d',
    },
    tokenSymbol: 'DESTINY',
    tokenAddresses: {
      137: '0x214188993b36bD8cD15f805Adee9eb49fD80BA74',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    earnSymbol: 'DESTINY',
    earnDecimal: 18,
    from: 'SANDMAN',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      137: '0xA00c3BeB61326dc1B3028bdf936690DF74D1e28c',
    },
    compoundFrequency: 3,
    performanceFee: 6.66,
  },
  {
    pid: 5,
    farmPid: 12,
    isTokenOnly: true,
    lpSymbol: 'DESIRE',
    decimal: 18,
    lpAddresses: {
      137: '0x5C2d9217aeF2B7478F3Cf301619CAd236c345567',
    },
    tokenSymbol: 'DESIRE',
    tokenAddresses: {
      137: '0xfbbEa521578059D8c2D53899E44c5A68b8ee88D8',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    earnSymbol: 'DESIRE',
    earnDecimal: 18,
    from: 'SANDMAN',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      137: '0xdCe933eeE65Dc041361BdcC55812263a92265cD8',
    },
    compoundFrequency: 3,
    performanceFee: 6.66,
  },
  {
    pid: 4,
    farmPid: 11,
    isTokenOnly: true,
    lpSymbol: 'DESTRUCTION',
    decimal: 18,
    lpAddresses: {
      137: '0x287bD6518aE54a4299BD766faa6DF3ED795fB6C1',
    },
    tokenSymbol: 'DESTRUCTION',
    tokenAddresses: {
      137: '0xCa4992F01B63C7cEB98505946b79D7D8855449F9',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    earnSymbol: 'DESTRUCTION',
    earnDecimal: 18,
    from: 'SANDMAN',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      137: '0xc255e6A8d37d9aa12A8FfA5700259b17C1704A94',
    },
    compoundFrequency: 3,
    performanceFee: 6.66,
  },
  {
    pid: 3,
    farmPid: 10,
    isTokenOnly: true,
    lpSymbol: 'DESPAIR',
    decimal: 18,
    lpAddresses: {
      137: '0x92BB40fa63EB4f402Cae5bDDAb3369a4Fb3e33bF',
    },
    tokenSymbol: 'DESPAIR',
    tokenAddresses: {
      137: '0x898F53e0365b3e8114227Df4E811766afCA960b8',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    earnSymbol: 'DESPAIR',
    earnDecimal: 18,
    from: 'SANDMAN',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      137: '0x7407cfAdc0CeB30DB65C95CA5337963A7C376706',
    },
    compoundFrequency: 3,
    performanceFee: 6.66,
  },
  {
    pid: 2,
    farmPid: 9,
    isTokenOnly: true,
    lpSymbol: 'DELIRIUM',
    decimal: 18,
    lpAddresses: {
      137: '0x2F051dB1634eEa2081590860ab8E756D452f2239',
    },
    tokenSymbol: 'DELIRIUM',
    tokenAddresses: {
      137: '0x238779aFfE6FFD475cB7e84582FcA7789F310Dc8',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    earnSymbol: 'DELIRIUM',
    earnDecimal: 18,
    from: 'SANDMAN',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      137: '0x62793094a9a46fAc2dc56a0218AFA706a4C478A5',
    },
    compoundFrequency: 3,
    performanceFee: 6.66,
  },
]

export default vaults
