import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import vaultchefABI from 'config/abi/vaultChef.json'
import vaultDestinyStrategyABI from 'config/abi/vaultDestinyStrategy.json'
import multicall from 'utils/multicall'
import vaultsConfig from 'config/constants/vaults'
import { getVaultChefAddress } from 'utils/addressHelpers'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

export const fetchVaultUserAllowances = async (account: string) => {
  const vaultChefAdress = getVaultChefAddress()

  const calls = vaultsConfig.map((vault) => {
    const lpContractAddress = vault.isTokenOnly ? vault.tokenAddresses[CHAIN_ID] : vault.lpAddresses[CHAIN_ID]
    return { address: lpContractAddress, name: 'allowance', params: [account, vaultChefAdress] }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchVaultUserTokenBalances = async (account: string) => {
  const calls = vaultsConfig.map((vault) => {
    const lpContractAddress = vault.isTokenOnly ? vault.tokenAddresses[CHAIN_ID] : vault.lpAddresses[CHAIN_ID]
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })

  return parsedTokenBalances
}

export const fetchVaultUserStakedBalances = async (account: string) => {
  const vaultChefAdress = getVaultChefAddress()

  const calls = vaultsConfig.map((vault) => {
    return {
      address: vaultChefAdress,
      name: 'userInfo',
      params: [vault.pid, account],
    }
  })

  const rawStakedBalances = await multicall(vaultchefABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  return parsedStakedBalances
}

export const fetchVaultUserEarnings = async (account: string) => {
  const vaultChefAdress = getVaultChefAddress()

  const calls1 = vaultsConfig
    .filter(vault => vault.abiFile === 'vaultDestinyStrategy')
    .map((vault) => {
      return {
        address: vault.strategyAddresses[process.env.REACT_APP_CHAIN_ID],
        name: 'pendingReward',
        params: [account],
      }
    })

  const rawEarnings1 = await multicall(vaultDestinyStrategyABI, calls1)

  const calls2 = vaultsConfig
    .filter(vault => vault.abiFile !== 'vaultDestinyStrategy')
    .map((vault) => {
      return {
        address: vaultChefAdress,
        name: 'stakedWantTokens',
        params: [vault.pid, account],
      }
    })
  const rawEarnings2 = await multicall(vaultchefABI, calls2)

  const parsedEarnings = rawEarnings1.concat(rawEarnings2).map((earnings) => {
    return new BigNumber(earnings).toJSON()
  })

  return parsedEarnings
}
