import addresses from 'config/constants/contracts'

const chainId = process.env.REACT_APP_CHAIN_ID

export const getCakeAddress = () => {
  return addresses.cake[chainId]
}
export const getUsdcAddress = () => {
  return addresses.usdc[chainId]
}
export const getDeliriumAddress = () => {
  return addresses.delirium[chainId]
}
export const getMasterChefAddress = () => {
  return addresses.masterChef[chainId]
}
export const getVaultChefAddress = () => {
  return addresses.vaultChef[chainId]
}
export const getMulticallAddress = () => {
  return addresses.mulltiCall[chainId]
}
export const getWbnbAddress = () => {
  return addresses.wbnb[chainId]
}
export const getLotteryAddress = () => {
  return addresses.lottery[chainId]
}
export const getSandmanReferralAddress = () => {
  return addresses.destinyReferral[chainId]
}
export const getLotteryTicketAddress = () => {
  return addresses.lotteryNFT[chainId]
}
export const getInstinctTokenAddress = () => {
  return addresses.instinctToken[chainId]
}
export const getInstinctSwapAddress = () => {
  return addresses.instinctSwap[chainId]
}
export const getTheEndlessFactoryNFTAddress = () => {
  return addresses.theEndlessFactoryNFT[chainId]
}
export const getNftAirdropAddress = () => {
  return addresses.nftAirdrop[chainId]
}
export const getNftSaleAddress = () => {
  return addresses.nftSale[chainId]
}
export const getNftMergeApiAddress = () => {
  return addresses.nftMergeApi[chainId]
}
export const getNftMergeAddress = () => {
  return addresses.nftMerge[chainId]
}
