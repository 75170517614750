import { PoolConfig, PoolCategory } from './types'
// import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 4,
    tokenName: 'DAI',
    stakingTokenName: 'DESTINY',
    stakingTokenAddress: '0x214188993b36bD8cD15f805Adee9eb49fD80BA74',
    stakingTokenLpAddress: '0x8dc7a8e7040832920831436Fb370ea12f661f797',
    stakingTokenQuoteTokenAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    contractAddress: {
      137: '0xaA51a1C9BfB60a6Ef598c987Da22D367C9579e9f',
    },
    lpAddress: {
      137: '0xEEf611894CeaE652979C9D0DaE1dEb597790C6eE',
    },
    tokenAddress: {
      137: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    },
    quoteTokenAdress: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://makerdao.com/',
    harvest: true,
    tokenPerBlock: '0.008948545861',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    stakingLimit: 0,
    poolBalance: 2000,
    withdrawLockup: 144,
    harvestLockup: 4,
    partnerColor: '182, 25, 36',
    experienceBalance: 2500,
  },
  {
    sousId: 3,
    tokenName: 'MAI',
    stakingTokenName: 'DESTINY',
    stakingTokenAddress: '0x214188993b36bD8cD15f805Adee9eb49fD80BA74',
    stakingTokenLpAddress: '0x8dc7a8e7040832920831436Fb370ea12f661f797',
    stakingTokenQuoteTokenAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    contractAddress: {
      137: '0xDADD992C362fb409c39ADC67F354B35e0C30316f',
    },
    lpAddress: {
      137: '0x7805B64e2d99412d3b8F10Dfe8fc55217C5cc954',
    },
    tokenAddress: {
      137: '0xa3fa99a148fa48d14ed51d610c367c61876997f1',
    },
    quoteTokenAdress: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.mai.finance/',
    harvest: true,
    tokenPerBlock: '0.01388888889',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    stakingLimit: 0,
    poolBalance: 2000,
    withdrawLockup: 96,
    harvestLockup: 4,
    partnerColor: '182, 25, 36',
    experienceBalance: 2500,
  },
  {
    sousId: 2,
    tokenName: 'DAI',
    stakingTokenName: 'DESTINY',
    stakingTokenAddress: '0x214188993b36bD8cD15f805Adee9eb49fD80BA74',
    stakingTokenLpAddress: '0x8dc7a8e7040832920831436Fb370ea12f661f797',
    stakingTokenQuoteTokenAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    contractAddress: {
      137: '0xeA66b389Aa4f9E98900511cB8513290EABaf758d',
    },
    lpAddress: {
      137: '0xEEf611894CeaE652979C9D0DaE1dEb597790C6eE',
    },
    tokenAddress: {
      137: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    },
    quoteTokenAdress: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://makerdao.com/',
    harvest: true,
    tokenPerBlock: '0.01722652885',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    stakingLimit: 0,
    poolBalance: 2000,
    withdrawLockup: 72,
    harvestLockup: 4,
    partnerColor: '182, 25, 36',
    experienceBalance: 5000,
  },
  {
    sousId: 1,
    tokenName: 'MAI',
    stakingTokenName: 'INSTINCT',
    stakingTokenAddress: '0xEc22E26DA6b5E841ad763A2C739F0F482915cD0e',
    stakingTokenLpAddress: '0x8dc7a8e7040832920831436Fb370ea12f661f797',
    stakingTokenQuoteTokenAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    contractAddress: {
      137: '0x79c596334C634D00470D76bF676bbe9E60664bDa',
    },
    lpAddress: {
      137: '0x7805B64e2d99412d3b8F10Dfe8fc55217C5cc954',
    },
    tokenAddress: {
      137: '0xa3fa99a148fa48d14ed51d610c367c61876997f1',
    },
    quoteTokenAdress: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.mai.finance/',
    harvest: true,
    tokenPerBlock: '0.01851851852',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    stakingLimit: 0,
    poolBalance: 1500,
    withdrawLockup: 48,
    harvestLockup: 4,
    partnerColor: '182, 25, 36',
    experienceBalance: 2500,
  },
]

export default pools
